// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var jQuery = require("jquery");


// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;



import "../stylesheets/application.scss"

require('jquery-ui')
require('admin-lte')
require('@nathanvda/cocoon')


require ("summernote/dist/summernote-bs4.min")


import "jquery-ui-dist/jquery-ui.min"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "overlayscrollbars/js/jquery.overlayScrollbars.min"
import "select2/dist/js/select2"
import("daterangepicker/daterangepicker")

import "datatables.net/js/jquery.dataTables.min"
import "datatables.net-bs4/js/dataTables.bootstrap4.min"
import "datatables.net-responsive/js/dataTables.responsive"
import "datatables.net-responsive-bs4/js/responsive.bootstrap4.min"

import "@fortawesome/fontawesome-free/js/all.min"
require('blueimp-file-upload')

document.addEventListener("turbolinks:load", () => {
    $("#DataTable").DataTable({
        "responsive": true,
        "autoWidth": false,
        "order": []
    });
    $("#DataTable").css("width","100%");

    $('input[type="file"]').change(function(e){
        var id = $(this).attr('id');
        var fileName = e.target.files[0].name;
        $('.custom-file-label[for='+id+ ']').html(fileName);
    });



})


import "./select2_initialize"
import "./pageload"
import "./file_upload"
import "./direct_upload.coffee.erb"
import "./summernote"
import "./notice"
import "./member.js"
import "./fullcalendar"
